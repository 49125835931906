import { PhoenixBaseCard } from 'componix'
import { UUID } from 'crypto'
import React, { useEffect, useState } from 'react'
import { useAlertContext } from '../../contexts/AlertContext'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import { getUnmatchedTransaction } from '../../services/workItemService'
import { AlertSeverity } from '../AlertNotification/AlertNotification'
import TransactionDetails from './TransactionDetails'
import UnmatchedTransactionSummary from './UnmatchedTransactionSummary'

interface UnmatchedTransactionProps {
  uuid: UUID
}

const UnmatchedTransaction = ({ uuid }: UnmatchedTransactionProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [transaction, setTransaction] = useState<UnmatchedTransactionModel | null>(null)
  const { setDetails } = useAlertContext()

  const fetchUnmatchedTransaction = async () => {
    getUnmatchedTransaction(uuid)
      .then((data: UnmatchedTransactionModel) => {
        if (data) {
          setTransaction(data)
        }
      })
      .catch(() => {
        setDetails({ message: 'Please refresh the page and try again.', severity: AlertSeverity.ERROR })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    fetchUnmatchedTransaction()
    //eslint-disable-next-line
  }, [uuid])

  const handleFindMatch = () => {
    console.log('find match:' + uuid)
  }

  return (
    <PhoenixBaseCard cardTitle="Policy Unmatched Transaction">
      <UnmatchedTransactionSummary data={transaction} isLoading={isLoading} matchFunc={handleFindMatch} />
      <TransactionDetails data={transaction} isLoading={isLoading} />
    </PhoenixBaseCard>
  )
}

export default UnmatchedTransaction
