import WorkItemDetailsModel from '../../models/WorkItemDetails/WorkItemDetailsModel'

export const WorkItemDetailsMock: WorkItemDetailsModel = {
  workQueue_WorkItemID: 'string',
  entityType: 'unmatched-transaction',
  workItemNumber: '123456',
  workItemDescription: 'string',
  workItemStatus: 'O',
  assignedDate: 'MM/DD/YYYY',
  dueDate: 'MM/DD/YYYY',
  assignedBy: 'System',
  assignedTo: 'UserName',
  isPastDue: true,
  associatedEntities: {
    policyId: 'fb276d6c-581c-4a30-8ead-713219a19928',
    comboId: '123456',
    unitStatId: '986750d1-5cb6-4ea6-b4b7-00000881b473',
  },
}

export default WorkItemDetailsMock
