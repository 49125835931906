import { Box, Button } from '@mui/material'
import { DataFieldBlankValue, PhoenixBaseTable, PhoenixLink } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import React, { useMemo } from 'react'
import { UnmatchedTransactionModel } from '../../models/WorkItemDetails/UnmatchedTransactionModel'

interface UnmatchedTransactionSummaryProps {
  data?: UnmatchedTransactionModel | null
  isLoading: boolean
  matchFunc?: () => void
}

const UnmatchedTransactionSummary = ({ data, isLoading, matchFunc }: UnmatchedTransactionSummaryProps) => {
  /* istanbul ignore next */
  const handleFindMatch = () => {
    if (matchFunc) {
      matchFunc()
    }
  }

  /* eslint react/prop-types: 0 */ /* istanbul ignore next */
  const columns = useMemo<MRT_ColumnDef<UnmatchedTransactionModel>[]>(
    () => [
      {
        accessorKey: 'combo',
        header: 'Combo ID',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ row }) => (
          <>
            {row.original.combo?.guid ? (
              <PhoenixLink
                id={`Combo-${row.index}`}
                to={`/combos/${row.original.combo?.guid}`}
                value={row.original.combo?.identifier?.toString()}
              />
            ) : (
              <DataFieldBlankValue />
            )}
          </>
        ),
      },
      {
        accessorKey: 'coverage',
        header: 'Coverage ID',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ row }) => (
          <>
            {row.original.coverage?.identifier ? (
              <PhoenixLink
                id={`Coverage-${row.index}`}
                to={`/coverages/${row.original.coverage?.identifier}`}
                value={row.original.coverage?.identifier?.toString()}
              />
            ) : (
              <DataFieldBlankValue />
            )}
          </>
        ),
      },
      {
        accessorKey: 'employer',
        header: 'Employer',
      },
      {
        accessorKey: 'address',
        header: 'Address',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ row }: any) => {
          return row.original.address ? (
            <>
              {row.original.address.street ? row.original.address.street + ', ' : ''}
              {row.original.address.city ? row.original.address.city + ', ' : ''}
              {row.original.address.state ? row.original.address.state + ', ' : ''}
              {row.original.address.zipCode ? row.original.address.zipCode.substring(0, 5) : ''}
            </>
          ) : (
            <DataFieldBlankValue />
          )
        },
      },
      {
        accessorKey: 'fein',
        header: 'FEIN',
      },
      {
        accessorKey: 'isWorkItemOpen',
        header: '',
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        Cell: ({ cell }: any) => {
          return (
            cell.getValue() && (
              <Box sx={{ textAlign: 'right' }}>
                <Button variant="contained" color="primary" size="small" onClick={() => handleFindMatch()}>
                  Find Match
                </Button>
              </Box>
            )
          )
        },
      },
    ],
    // eslint-disable-next-line
    []
  )

  return <PhoenixBaseTable data={data ? [data] : []} columns={columns} isLoading={isLoading} />
}

export default UnmatchedTransactionSummary
