//import { WorkItemDetailsModel } from '../models/WorkItemDetailsModel'
import { UnmatchedTransactionModel } from '../models/WorkItemDetails/UnmatchedTransactionModel'
import { WorkItemDetailsMock } from '../mocks/WorkItemDetails/WorkItemDetailsMock'
import { SuggestedMatchModel } from '../models/WorkItemDetails/SuggestedMatchModel'
import api, { ApiResponse } from '../utils/apiService'
import { Endpoints } from '../utils/endpoint'

export const getWorkItemDetails = async (workItemId: string) => {
  //const response: ApiResponse<WorkItemDetailsMock> = await api.get(`${Endpoints.WorkQueue}/WorkItem/Details/${workItemId}`)
  console.log(`Loading ${workItemId} Details mock`)
  return WorkItemDetailsMock //response.data
}

export const getUnmatchedTransaction = async (workItemId: string) => {
  const response: ApiResponse<UnmatchedTransactionModel> = await api.get(
    `${Endpoints.WorkQueue}/workItem/unmatchedtransaction/${workItemId}`
  )
  return response.data
}

export const getSuggestedMatches = async (workItemId: string) => {
  const response: ApiResponse<SuggestedMatchModel[]> = await api.get(
    `${Endpoints.WorkQueue}/workItem/suggestedMatches/${workItemId}`
  )
  return response.data
}
